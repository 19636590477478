<template>
  <div>
    <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
    <div v-show="!isSpinner">
      <!-- <user-list-add-new
        :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
        :role-options="roleOptions"
        :plan-options="planOptions"
        @refetch-data="refetchData"
      /> -->

      <!-- Filters -->
      <b-card
        title="Filters"
      >
        <b-row class="tableFilters">
          <b-col md="2">
            <b-form-group label="Country">
              <b-form-select
                  v-model="filterCountry"
                  :options="countryOptions"
                  placeholder="Select"
                  @change="getFilterStates()"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="State">
              <b-form-select
                  v-model="filterState"
                  :options="stateOptions"
                  placeholder="Select"
                  :disabled="!filterCountry"
                  @change="getFilterCities()"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="City">
              <b-form-select
                v-model="filterCity"
                :options="cityOptions"
                placeholder="Select"
                :disabled="!filterState"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"            
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  @input="handleSearch"
                />
                <!-- <b-button
                  variant="primary"
                  @click="isAddNewUserSidebarActive = true"
                >
                  <span class="text-nowrap">Add User</span>
                </b-button> -->
              </div>
            </b-col>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
            >
              
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <!-- <label>entries</label> -->
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="universityArray"
          responsive
          :fields="tableColumns"
          :current-page="1"
          :per-page="perPage"
          primary-key="id"
          :show-empty="!isSpinner"
          no-local-sorting
          v-model='docCategoryTable'
          :tbody-tr-class="rowClass"
          hover
          empty-text="No matching records found"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          @sort-changed="handleSortChange"
        >
          <!-- Column: No. -->
          <template #cell(no)="data">
            <div>
                <span>{{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}</span>
            </div>
          </template>

          <!-- Column: City -->
          <template #cell(city)="data">
            <div style="white-space: nowrap">
              {{data.item.city.name}}
            </div>
          </template>

          <!-- Column: State -->
          <template #cell(state)="data">
            <div style="white-space: nowrap">
              {{data.item.state.name}}
            </div>
          </template>

          <!-- Column: Country -->
          <template #cell(country)="data">
            <div style="white-space: nowrap">
              {{data.item.country.name}}
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div style="white-space: nowrap;display:flex;justify-content: end;align-items: center;" v-if="$route.meta.action=='write' || $route.meta.action==undefined" @click.stop.prevent>
              <img src='@/assets/images/erflog/Edit.png' @click.prevent="getData(data),selectedUniversity=data" v-if="!data.item.isDeleted" v-b-modal.edit-university class="action_icon"/>
              <!-- <img src='@/assets/images/erflog/enable_new.png' v-if="!data.item.isDeleted" style="cursor:pointer" @click.prevent="selectedUniversity=data" v-b-modal.delete-university class="action_icon"/>
              <img src='@/assets/images/erflog/disable_new.png' v-if="data.item.isDeleted" style="cursor:pointer !important" @click.prevent="selectedUniversity=data" v-b-modal.delete-university class="action_icon"/> -->
              <b-icon-toggle-on class="action_icon focusRemoveToggle" variant="success" width="30px" height="22px" v-if="!data.item.isDeleted" style="cursor:pointer" @click.prevent="selectedUniversity=data" v-b-modal.delete-university></b-icon-toggle-on>
              <b-icon-toggle-off class="action_icon focusRemoveToggle" variant="secondary" width="30px" height="22px" v-if="data.item.isDeleted" style="cursor:pointer !important" @click.prevent="selectedUniversity=data" v-b-modal.delete-university></b-icon-toggle-off>
            </div>
            <div v-else>
              --
            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of  }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                @change="handlePageChange"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

      <!-- Create University -->
      <b-modal
        id="create-university"
        ok-variant='primary'
        cancel-variant="outline-secondary"
        ok-title='Save'
        cancel-title="Cancel"
        centered
        size="lg"        
        title="Create University"
        :cancel-disabled='modalLoader'
        :no-close-on-backdrop="true"        
        @hidden="(e)=>{e.preventDefault();selectedUniversity='';resetData()}"
        @ok="(e)=>{e.preventDefault();createNewUniversityFunction(e);}"
        @cancel="(e)=>{cloaseCreateUniversity(e)}"
        :ok-disabled='modalLoader'
      >
        <validation-observer ref="personalInfoForm1">
          <!-- form -->
          <b-form>
            <b-row>
              <b-spinner variant="primary" v-if="modalLoader" large class="pageLoadSpinners"/>
              <!-- Name -->
              <b-col md="6">
                  <b-form-group label="University Name">
                  <validation-provider
                      #default="{ errors }"
                      name="university name"
                      rules="required"
                  >
                      <b-form-input
                          v-model="universityName"
                          :disabled="modalLoader"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Text"
                          @blur="universityName=universityName.trim()"
                      />
                      <!-- <small class="text-danger" v-if="duplicateUniversityName && !duplicateCollgeName">University already exists.</small>
                      <small class="text-danger" v-else-if="!duplicateUniversityName && duplicateCollgeName">College already exists.</small>
                      <small class="text-danger" v-else-if="duplicateUniversityName && duplicateCollgeName">University and College already exists.</small> -->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Address Line 1 -->
              <b-col md="6">
                  <b-form-group label="Address Line 1">
                  <validation-provider
                      #default="{ errors }"
                      name="address line 1"
                      rules="required"
                  >
                      <b-form-input
                          v-model="addressLine1"
                          :disabled='modalLoader'
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Text"
                          @blur="addressLine1=addressLine1.trim()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Address Line 2 -->
              <b-col md="6">
                  <b-form-group label="Address Line 2">
                  <validation-provider
                      #default="{ errors }"
                      name="address line 2"
                      rules=""
                  >
                      <b-form-input
                          v-model="addressLine2"
                          :disabled='modalLoader'
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Text"
                          @blur="addressLine2=addressLine2.trim()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Country -->
              <b-col md="6">
                  <b-form-group label="Country">
                  <validation-provider
                      #default="{ errors }"
                      name="country"
                      rules="required"
                  >
                      <b-form-select
                          v-model="country"
                          :disabled='modalLoader'
                          :options="countryOptions"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select"
                          @change="getStates()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- State -->
              <b-col md="6">
                  <b-form-group label="State">
                  <validation-provider
                      #default="{ errors }"
                      name="state"
                      rules="required"
                  >
                      <b-form-select
                          v-model="state"
                          :options="stateOptions"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select"
                          :disabled="modalLoader || !country"
                          @change="getCities()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- City -->
              <b-col md="6">
                  <b-form-group label="City">
                  <validation-provider
                      #default="{ errors }"
                      name="city"
                      rules="required"
                  >
                      <b-form-select
                          v-model="city"
                          :disabled="modalLoader || !state"
                          :options="cityOptions"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Pincode -->
              <b-col md="6">
                  <b-form-group label="Pincode">
                  <validation-provider
                      #default="{ errors }"
                      name="pincode"
                      rules="required"
                  >
                      <b-form-input
                          v-model="pincode"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Pincode"
                          :disabled="modalLoader"
                          @blur="pincode=pincode.trim()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- isCollegeNameSame -->
              <b-col md="12">
                  <b-form-group label="">
                  <validation-provider
                      #default="{ errors }"
                      name="university name exist"
                      rules=""
                  >
                      <b-form-checkbox
                        v-model="isCollegeNameSame"
                        plain
                        :disabled="modalLoader"
                        :state="errors.length > 0 ? false:null"
                        :value="true"
                      >
                        University name and college name are the same?
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>
              <!-- is invoice generated or not -->
              <b-col md="12">
                  <b-form-group label="">
                  <validation-provider
                      #default="{ errors }"
                      name="invoice"
                      rules=""
                  >
                      <b-form-checkbox
                        v-model="isInvoice"
                        plain
                        :disabled="modalLoader"
                        :state="errors.length > 0 ? false:null"
                        :value="true"
                      >
                      Invoice
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>
              <!-- <div class="foater">
                  <b-button variant="primary" size="sm" class="mt-2 mr-2">save</b-button>
                  <b-button  variant="outline-secondary" size="sm" class="mt-2 ml-2">Cancel</b-button>
              </div> -->
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>


      <!-- Edit University -->
      <b-modal
        id="edit-university"
        cancel-variant="outline-secondary"        
        ok-title="Save"
        cancel-title="Cancel"
        centered
        size="lg"  
        ok-variant='primary'    
        title="Edit University"
        :no-close-on-backdrop="true"
        @hidden="resetData(),selectedUniversity='',editUniCollegeAdd = true,isCollegeNameSame = false,isInvoice = false"        
        @ok="(e)=>{e.preventDefault();editSelectedUniversity(e);}"
       :cancel-disabled='modalLoader' 
       :ok-disabled='modalLoader'
      >
        <validation-observer ref="personalInfoForm">
          <!-- form -->
          <b-form>
            <b-spinner variant="primary" v-if="modalLoader" large class="pageLoadSpinners"/>
            <b-row>
              <!-- Name -->
              <b-col md="6">
                  <b-form-group label="University Name">
                  <validation-provider
                      #default="{ errors }"
                      name="university name"
                      rules="required"
                  >
                      <b-form-input
                          v-model="universityName"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Text"
                          :disabled='true'
                          @blur="universityName=universityName.trim()"
                      />
                      <!-- <small class="text-danger" v-if="!duplicateEditUniversityName">{{ errors[0] }}</small>
                      <small class="text-danger" v-else>University already exists.</small> -->
                  </validation-provider>
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Address Line 1 -->
              <b-col md="6">
                  <b-form-group label="Address Line 1">
                  <validation-provider
                      #default="{ errors }"
                      name="address line 1"
                      rules="required"
                  >
                      <b-form-input
                          v-model="addressLine1"
                          :disabled='modalLoader'
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Text"
                          @blur="addressLine1=addressLine1.trim()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Address Line 2 -->
              <b-col md="6">
                  <b-form-group label="Address Line 2">
                  <validation-provider
                      #default="{ errors }"
                      name="address line 2"
                      rules=""
                  >
                      <b-form-input
                          v-model="addressLine2"
                          :disabled='modalLoader'
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Text"
                          @blur="addressLine2=addressLine2.trim()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Country -->
              <b-col md="6">
                  <b-form-group label="Country">
                  <validation-provider
                      #default="{ errors }"
                      name="country"
                      rules="required"
                  >
                      <b-form-select
                          v-model="country"
                          :disabled='modalLoader'
                          :options="countryOptions"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select"
                          @change="getStates()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- State -->
              <b-col md="6">
                  <b-form-group label="State">
                  <validation-provider
                      #default="{ errors }"
                      name="state"
                      rules="required"
                  >
                      <b-form-select
                          v-model="state"
                          :options="stateOptions"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select"
                          :disabled="modalLoader && !country"
                          @change="getCities()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- City -->
              <b-col md="6">
                  <b-form-group label="City">
                  <validation-provider
                      #default="{ errors }"
                      name="city"
                      rules="required"
                  >
                      <b-form-select
                          v-model="city"
                          :disabled="modalLoader && !state"
                          :options="cityOptions"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Pincode -->
              <b-col md="6">
                  <b-form-group label="Pincode">
                  <validation-provider
                      #default="{ errors }"
                      name="pincode"
                      rules="required"
                  >
                      <b-form-input
                          v-model="pincode"
                          :disabled='modalLoader'
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Pincode"
                          @blur="pincode=pincode.trim()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- isCollegeNameSame -->
              <b-col md="12">
                  <b-form-group label="">
                  <validation-provider
                      #default="{ errors }"
                      name="university name exists 2"
                      rules=""
                  >
                      <b-form-checkbox
                        v-model="isCollegeNameSame"
                        plain
                        :disabled='modalLoader || editUniCollegeAdd == false'
                        :state="errors.length > 0 ? false:null"
                        :value="true"
                      >
                        University name and college name are the same?
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>
              <!-- isInvoice -->
              <b-col md="12">
                  <b-form-group label="">
                  <validation-provider
                      #default="{ errors }"
                      name="invoice"
                      rules="required"
                  >
                      <b-form-checkbox
                        v-model="isInvoice"
                        plain
                        :disabled='modalLoader'
                        :state="errors.length > 0 ? false:null"
                        :value="true"
                      >
                      Invoice
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>

      <!-- Delete University -->
      <b-modal
        id="delete-university"
        cancel-variant="outline-secondary"
        ok-title="Yes"
        cancel-title="No"
        centered
        ok-variant='primary'
        title=""
        :no-close-on-backdrop="true"
        @hidden="selectedUniversity=''"
        v-if="selectedUniversity!=''"
        @ok="deleteFieldDataAfterConfirm(selectedUniversity)"
      >
      <div class="create_account_img">
        <img src="@/assets/images/erflog/Approve_Popup.png" alt="">
      </div>
        <div style="text-align: center;" v-if="selectedUniversity!=''">
          <label class="s_modal_title" v-if="!selectedUniversity.item.isDeleted">Disable University</label>
          <label class="s_modal_title" v-if="selectedUniversity.item.isDeleted">Enable University</label>
          <p v-if="!selectedUniversity.item.isDeleted">Are you sure you want to disable {{selectedUniversity.item.field_name}}? <b>This will also disable associated all colleges with this University.</b></p>
          <p v-if="selectedUniversity.item.isDeleted">Are you sure you want to enable {{selectedUniversity.item.field_name}}? <b>This will only enable university not associated colleges</b></p>
        </div>
      </b-modal>
      <importModel 
      :openImportMOdel='openImportMOdel' 
      :modelTitle="modelTitle" 
      :fileName='fileName' 
      :row='row'
      :ReportTitle='ReportTitle'
      @closeImportCsvModel='closeModel'
      @importCsvFile='importCsvFile'
      :fieldError='fieldError'
      :isSppiner='isSppiner'
      />

      
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BFormCheckbox,
  BBadge, BDropdown, BDropdownItem, BPagination,BModal,BFormGroup,BFormRadio,BFormDatepicker,BIconToggleOff,BIconToggleOn,
  BForm,BFormTextarea,BFormSelect,BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useUniversityList'
//vee-validator
import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'

import { Country, State, City }  from 'country-state-city';

import { required, email,max,max_value,integer,numeric,min,min_value,alpha_spaces,double } from 'vee-validate/dist/rules';

extend('required',required)
extend('max',max)
extend('min',min)
extend('min_value',min_value)
extend('email',email)
extend('alpha_spaces',alpha_spaces)
extend('max_value',max_value)
extend('integer',integer)
extend('numeric',numeric)
extend('double',double)

import firebase from '@/utils/firebaseInit'
import { dbCollections } from '@/utils/firebaseCollection'

const db = firebase.firestore()

import Ripple from 'vue-ripple-directive'

import { ValidationFunction } from '@/utils/globalValidations.js';

import importModel from '@/views/components/importModel.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { importCSVData } from '@/utils/importExportFiles/importFunction.js'
import firebaseTimeStamp from "firebase"
import * as inserQuery from '@/utils/FirebaseQueries/insertQueries/insertQueries.js'
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js'
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import { getCollectionData } from '@/utils/dynamicGetDataQuery'
export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider, 
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormGroup,
    BFormRadio,
    BFormDatepicker,
    BFormTextarea,
    BFormSelect,
    BFormCheckbox,
    vSelect,
    importModel,
    BSpinner,
    BIconToggleOff,
    BIconToggleOn
  },
  data(){
    return{
      editUniCollegeAdd:true,
      universityName:"",
      addressLine1:'',
      addressLine2: '',
      pincode:'',
      state:null,
      city:null,
      country:null,
      countryOptions: [{value:null,text:'All'}],
      stateOptions: [{value:null,text:'All'}],
      cityOptions: [{value:null,text:'All'}],
      isCollegeNameSame:false,
      isInvoice:false,

      universities:[],
      college:[],
      selectedUniversity:'',
      openImportMOdel:false,
      modelTitle:'',
      fileName:'',
      csvHeader: {
        'universityName' : "Sardar patel university",      
        'addressLine1':'Vallabh Vidyanagar',
        'addressLine2':'Anand',
        'country':'India',
        'state':'Gujarat',
        'city':'Anand',
        'pincode':'380112',
        'uniCllgNameSame':'yes'        
      },
      ReportTitle:['UniversityName','AddressLine1','AddressLine2','Country','State','City','Pincode','SameCollegeName'],
      row:'',
      fieldError:'',
      isSppiner:false,
      universityTableListing:[],
      modalLoader:false,
      //Added University
      addedUni:[],
      allColleges:[],
      // allUniversity:[],
      allUniData:[],
      disabledField: false
    }
  },
  created() {
    var self = this
    self.disabledField = true;
    self.fetchUniversityFromTypesense(true,true,()=>{});
    // setTimeout(()=>{
    //   console.log(this.fetchMoreDataStop,'this.fetchMoreDataStop')
    //   if(this.fetchMoreDataStop == false) {
    //     const collegesPromise = getCollectionData(`${dbCollections.COLLEGES}`,[], {},0,'','','','',0)
    //     const universitiesPromise = getCollectionData(`${dbCollections.UNIVERSITIES}`,[], {},0,'','','','',0)
        
    //     Promise.allSettled([collegesPromise, universitiesPromise])
    //     .then((results) => {
    //       const collgedocs = results[0].status === 'fulfilled' ? results[0].value : [];
    //       const universitiesdocs = results[1].status === 'fulfilled' ? results[1].value : [];
  
    //       const collegesResult =  collgedocs.docs?.map((x) => ({...x.data(), id: x.id}));
    //       const universitiesResult =  universitiesdocs.docs?.map((x) => ({...x.data(), id: x.id}));
  
    //       self.allColleges = collegesResult
    //       self.college = collegesResult.map((e)=>e.collegeName);
          
    //       self.universities = universitiesResult.map((e)=>e.universityName)
    //       self.allUniversity = universitiesResult
    //       self.allUniData = universitiesResult.map(({ universityName, id }) => ({ name: universityName, id }));
    //       this.addedUni = [];

    //       if (self.allUniversity.length > 0) {
    //         const fileArray = self.allUniversity;
    //         const promises = fileArray.map(row => {
    //           return new Promise(resolve => {
    //             self.checkSameNameUniversity(row, saveRes => {
    //               this.addedUni.push(saveRes);
    //               resolve();
    //             });
    //           });
    //         });

    //         Promise.allSettled(promises)
    //           .then(() => {
    //             self.disabledField = false;
    //           })
    //           .catch(error => {
    //             console.error('Error settling promises:', error);
    //           });
    //       } else {
    //         self.checkSameNameUniversity(false, () => {
    //           self.disabledField = false;
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       self.disabledField = false;
    //       console.error('Error:', error);
    //     });
    //   }
    // },1000)
    var countries = Country.getAllCountries()
    self.countryOptions=[{value:null,text:'All'}]
    countries.forEach(data=>{
        self.countryOptions.push({value:data,text:data.name})
    })

    self.$root.$on('createNewUniversity',()=>{
      self.$bvModal.show('create-university')
    })

    self.$root.$on('exportUniversity',()=>{
      let exportArray = [];
      if(this.allUniversitys.length > 0){
          let header = ["UniversityName","AddressLine1","AddressLine2","Country","State","City","Pincode","SameCollegeName"];
          exportArray.push(header)
          if(this.allUniversitys.length > 0){
            this.allUniversitys.forEach((values)=>{
              if(values){  
                let sameCllgExist = 'no';
                if(this.addedUni.length > 0){
                  let getIndex = this.addedUni.findIndex(elements=>{
                    return elements.id == values.id
                  })
                  if(getIndex > -1){
                    if(this.addedUni[getIndex].sameNameCollege !== '' && this.addedUni[getIndex].sameNameCollege !== null){
                      sameCllgExist = 'yes';
                    }
                  }
                }
                
                let tempArray = [
                  values.universityName.toString(),
                  values.addressLine1.toString() ? values.addressLine1.toString() : '-' ,
                  values.addressLine2.toString().trim() ? values.addressLine2.toString().trim() : '-',
                  values.country.name.toString(),
                  values.state.name.toString(),
                  values.city.name.toString(),                 
                  values.pincode.toString() ? values.pincode.toString() : '-',
                  sameCllgExist
                ]
                exportArray.push(tempArray)
              }else{
                return
              }
            })  
            let array = [];
            array = exportArray;
            this.exportCsvFile(array,res=>{
              if(res){
               setTimeout(()=>{
                  this.$root.$emit('stopSpinner')
                },1000)     
              }
            })                  
          }
      }else{
       this.$root.$emit('showToastMessage','No record found','danger') 
      }
    })

    self.$root.$on('importUniversity',()=>{
      this.row=''; 
      this.fieldError = '';      
      self.modelTitle="Import University List"
      self.fileName='UniversityDetails';            
      // var arrData = typeof this.csvHeader != "object" ? JSON.parse(this.csvHeader) : this.csvHeader;       
      this.row = [  
                ['UniversityName','AddressLine1','AddressLine2','Country','State','City','Pincode','SameCollegeName'],
                ['Sardar Patel University','Vallabh Vidyanagar','Anand','India','Gujarat','Anand','380112','yes'],
                ['Gujarat Technological University','Vallabh Vidyanagar','Anand','India','Gujarat','Anand','380112','no']
            ] 
      this.row = JSON.stringify(this.row)       
      self.openImportMOdel = true; 
    })
 
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      deleteField,

      createUniversity,
      editUniversity,
      editUniversityAndCollege,
      isSpinner,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      filterCountry,
      filterState,
      filterCity,
      refreshPage,
      allUniversitys,
      fetchUniversity,
      filteredUniversityList,
      isFetching,
      docCategoryTable,
      fetchMoreDataStop,
      universityArray,
      nextDisabled,
      fetchUniversityFromTypesense,
      handleSortChange,
      handlePageChange,
      handleSearch
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      deleteField,
      createUniversity,
      editUniversity,
      editUniversityAndCollege,
      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      isSpinner,

      // Extra Filters
      filterCountry,
      filterState,
      filterCity,
      refreshPage,
      allUniversitys,
      fetchUniversity,
      filteredUniversityList,
      isFetching,
      docCategoryTable,
      fetchMoreDataStop,
      universityArray,
      nextDisabled,
      fetchUniversityFromTypesense,
      handleSortChange,
      handlePageChange,
      handleSearch
    }
  },
  methods:{
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.isDeleted === true) return 'table-disbled-row'
    },
    exportCsvFile(array,cb){
        var str = '';       
        var filename = 'University List';
      for (var i = 0; i < array.length; i++) { 
            let line = '';
            line =array[i].join(',');
            str += line + '\r\n';
            }
            var blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });

            var link = document.createElement('a');
            var url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            cb(true)
    },
    getColleges(){
       getQuery.getDataFromRootCollection(dbCollections.COLLEGES,res=>{
         this.allColleges = [];
         if(res){
           this.allColleges = res;
         }
       })
    },
    deleteFieldDataAfterConfirm(data){
      this.deleteField({...data},cb=>{
        if(cb.opt == true){
          this.$root.$emit('showToastMessage',cb.msg,'success')
        }else{
          this.$root.$emit('showToastMessage',cb.msg,'danger')
        }
      })
    }, 
    checkSameNameUniversity(data,cb){
      // console.log(data,"&&&&&&&&&&&&&&&")
      if(data == false){
        cb(false)
      }else{
          if(data !== null){
            if(this.allColleges.length > 0){
              let mathObj = null
              this.allColleges.forEach(elem=>{
                if(elem.collegeName.toLowerCase().trim() == data.universityName.toLowerCase().trim()){
                  mathObj = elem
                }                
              })
              let returnObject = null;
              if(mathObj !== null){
                  returnObject = {
                    'universityName':data.universityName,
                    'id':data.id,
                    'sameNameCollege':mathObj.id,
                    'obj':mathObj
                  }
              }else{
                  returnObject = {
                    'universityName':data.universityName,
                    'id':data.id,
                    'sameNameCollege':''
                  }
              }
              cb(returnObject)
            }else{
              let returnObject = {
                'universityName':data.universityName,
                'id':data.id,
                'sameNameCollege':""
              }
              cb(returnObject)
            }
          }
      }
    },
    getStates() 
    {
      var self = this

      self.state=null
      self.city=null
      
      self.stateOptions=[{value:null,text:'All'}]
      var states = State.getStatesOfCountry(self.country?.isoCode)
      states.forEach(data=>{
        self.stateOptions.push({value:data,text:data.name})
      })
    },
    getCities()
    {
      var self = this

      self.city=null
      
      self.cityOptions=[{value:null,text:'All'}]
      var cities = City.getCitiesOfState(self.country?.isoCode,self.state?.isoCode)
      cities.forEach(data=>{
          self.cityOptions.push({value:data,text:data.name})
      })
    },
    getFilterStates()
    {
      var self = this

      self.filterState=null
      self.filterCity=null
      
      self.stateOptions=[{value:null,text:'All'}]
      var states = State.getStatesOfCountry(self.filterCountry?.isoCode)
      states.forEach(data=>{
        self.stateOptions.push({value:data,text:data.name})
      })
    },
    getFilterCities()
    {
      var self = this

      self.filterCity=null
      
      self.cityOptions=[{value:null,text:'All'}]
      var cities = City.getCitiesOfState(self.filterCountry?.isoCode,self.filterState?.isoCode)
      cities.forEach(data=>{
        self.cityOptions.push({value:data,text:data.name})
      })
    },
    getCollegeInfo(uniId,cb){
      getQuery.getDataWithSpecificWhereCondition(dbCollections.COLLEGES,'universityId','==',uniId,res=>{
        cb(res)
      })
    },
    getData(data)
    {
      var self = this
      if(Object.keys(data.item).length > 0){
        this.getCollegeInfo(data.item.id,res=>{
          if(res !== undefined && res.length > 0){
            res.forEach((cllg)=>{
              if(cllg.collegeName.toLowerCase() == data.item.university_name.toLowerCase()){
                this.editUniCollegeAdd = false;
                this.isCollegeNameSame = true;
              }
            })
          }
          self.universityName=data.item.university_name      
          self.isInvoice=data.item.isInvoiceGenerated      
          self.country=data.item.country
          self.getStates()
          self.state=data.item.state
          self.getCities()
          self.city=data.item.city


          self.addressLine1=data.item.address.split(", ")[0].trim()
          if(data.item.address.split(", ").length == 3)
          {
            self.pincode=data.item.address.split(", ")[2].trim()
            self.addressLine2=data.item.address.split(", ")[1].trim()
          }
          else
          {
            self.pincode=data.item.address.split(", ")[1].trim()
          }
        })
      }
    },
    resetData()
    {
      var self=this

      self.universityName=''
      self.country=null
      self.state=null
      self.city=null

      self.pincode=''
      self.addressLine1=''
      self.addressLine2=''
      self.isCollegeNameSame = false
      self.isInvoice = false
    },
    handleRowClick(data)
    {
      this.$router.push({name:'field-course-info',params: { id: data.id,name:data.field_name }})
    },
    isOnlyDecimal(event) {
      ValidationFunction.OnlyFloatWithNumber(event);
    },
    isOnlyInteger(event) {
      ValidationFunction.OnlyIntegerNumber(event);
    },
    createNewUniversityFunction(e) {
      if (this.modalLoader) return;

      const validateAndCreate = () => {
        this.$refs.personalInfoForm1.validate().then(success => {
          if (success) {
            this.modalLoader = true;
            const queryUniversity = [{ field: "universityName", operation: "==", value: this.universityName }];

            const createNewUniversity = () => {
              const universityData = {
                universityName: this.universityName,
                addressLine1: this.addressLine1,
                addressLine2: this.addressLine2,
                pincode: this.pincode,
                state: this.state,
                city: this.city,
                country: this.country,
                isCollegeNameSame: this.isCollegeNameSame,
                isInvoice: this.isInvoice
              };

              this.createUniversity(universityData, () => {
                this.modalLoader = false;
                this.$bvModal.hide('create-university');
                setTimeout(() => {
                  this.resetData();
                }, 500);
              });
            };

            const handleCollegeCheck = () => {
              if (this.isCollegeNameSame) {
                const queryCollege = [{ field: "collegeName", operation: "==", value: this.universityName }];

                getCollectionData(`${dbCollections.COLLEGES}`, queryCollege, {}, 0, '', '', '', '', 0).then(resCollege => {
                  if (resCollege.empty) {
                    createNewUniversity();
                  } else {
                    this.modalLoader = false;
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'College already exists.',
                        icon: 'CoffeeIcon',
                        variant: 'danger',
                      },
                    });
                  }
                });
              } else {
                createNewUniversity();
              }
            };

            getCollectionData(`${dbCollections.UNIVERSITIES}`, queryUniversity, {}, 0, '', '', '', '', 0).then(resUniversity => {
              if (resUniversity.empty) {
                handleCollegeCheck();
              } else {
                this.modalLoader = false;
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'University already exists.',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                  },
                });
              }
            });
          } else {
            this.modalLoader = false;
            e.preventDefault();
          }
        });
      };
      validateAndCreate();
    },
   cloaseCreateUniversity(e){
     if(this.modalLoader !== false){
        e.preventDefault()
     }else{
     this.selectedUniversity = ''
     this.isCollegeNameSame = ''
     }
   },
    editSelectedUniversity(e)
    {
      var self = this
      if(self.modalLoader == false){
        self.$refs.personalInfoForm.validate().then(success=>{
        if(success)
        {
          self.modalLoader = true;
          var Unidata={
            id:self.selectedUniversity.item.id,
            index:self.selectedUniversity.index,

            universityName:self.universityName,
            addressLine1:self.addressLine1,
            addressLine2:self.addressLine2,
            pincode:self.pincode,
            state:self.state,
            city:self.city,
            country:self.country,
            isCollegeNameSame:self.isCollegeNameSame,
            isInvoice:self.isInvoice,
          }
            if(self.editUniCollegeAdd == true && self.isCollegeNameSame == true){
              self.editUniversityAndCollege(Unidata,res=>{
                self.modalLoader = false;
                self.editUniCollegeAdd = true
                self.isCollegeNameSame = false
                self.isInvoice = false
                self.$bvModal.hide('edit-university')
              })
            }
            if(self.editUniCollegeAdd == true && self.isCollegeNameSame == false){
              self.editUniversity(Unidata,res=>{
                self.modalLoader = false;
                self.editUniCollegeAdd = true
                self.isCollegeNameSame = false
                self.isInvoice = false
                self.$bvModal.hide('edit-university')
              })
            }
            if(self.editUniCollegeAdd == false && self.isCollegeNameSame == true){
              self.editUniversity(Unidata,res=>{
                self.modalLoader = false;
                self.editUniCollegeAdd = true
                self.isCollegeNameSame = false
                self.isInvoice = false
                self.$bvModal.hide('edit-university')
              })
            }
        }
        else
        {
          self.editUniCollegeAdd = true
          self.isCollegeNameSame = false
          self.isInvoice = false
          self.modalLoader = false;
          e.preventDefault()
        }
      });
      }
    },
    closeModel(event){
      this.openImportMOdel = event     
    },
    uploadImportFile(data,cb){     
      var self = this;
      let fileObject = data;
      var saveData = [];     
      if(fileObject !== undefined){
       importCSVData(fileObject, (res) => {
          if(res.length > 0){
            let countryObj = {};
            let stateObject = {};
            let cityObject = {};
            let isUpload = true;    
            var element = {}; 
            for (let i = 0; i < res.length; i++) { 
              element = res[i];  
              let importCountry_state = []; 
              let importState_city = [];
              countryObj = {};
              stateObject = {};
              cityObject = {};
              var keys = Object.keys(element);             
              if(keys[0] == "" ||keys[1] == "" || keys[2] == ""|| keys[3] == ""|| keys[4] == "" || keys[5] == ""|| keys[6] == ""){
                self.fieldError = "column name is required, [ please verify download-sample-file and upload file again ]";
                this.isSppiner = false;
                this.$root.$emit('errorUploadAgain')
                cb(false)
                isUpload = false;
                break;
              }             
             
              if(keys[0] != "UniversityName" || keys[1] != "AddressLine1"|| keys[2] != "AddressLine2"|| keys[3] != "Country"|| keys[4] != "State"|| keys[5] != "City"|| keys[6] != "Pincode"){
                self.fieldError = "Invalid column name, [ please verify download-sample-file and upload file again ]";
                this.isSppiner = false;
                this.$root.$emit('errorUploadAgain')
                cb(false)
                isUpload = false;                
                break;
              } 
              // if(element.UniversityName=="" || element.AddressLine1=="" || element.AddressLine2==""|| element.Country==""|| element.State==""|| element.City==""|| element.Pincode==""){
              if(element.UniversityName=="" || element.AddressLine1=="" || element.Country==""|| element.State==""|| element.City==""|| element.Pincode==""){
                self.fieldError = "Empty sheel not allow, [ please verify download-sample-file and upload file again ]";
                this.isSppiner = false;
                this.$root.$emit('errorUploadAgain')
                cb(false)
                isUpload = false;                
                break;
              } 
              if(element.UniversityName==undefined || element.AddressLine1==undefined || element.Country==undefined|| element.State==undefined|| element.City==undefined|| element.Pincode==undefined){
              // if(element.UniversityName==undefined || element.AddressLine1==undefined || element.AddressLine2==undefined|| element.Country==undefined|| element.State==undefined|| element.City==undefined|| element.Pincode==undefined){
                self.fieldError = "Empty sheel not allow, [ please verify download-sample-file and upload file again ]";
                this.isSppiner = false;
                this.$root.$emit('errorUploadAgain')
                cb(false)
                isUpload = false;                
                break;
              } 
               if(keys[7] !== undefined){ 
                  if(keys[7] !== "SameCollegeName"){
                    self.fieldError = "Invalid Last Column-Name, [ please verify download-sample-file and upload file again ]";
                    this.isSppiner = false;
                    cb(false)
                    isUpload = false;
                    break;
                  }else{
                    if(element.SameCollegeName.toString().trim() !== ""){
                        if(element.SameCollegeName.toLowerCase() !== 'yes' && element.SameCollegeName.toLowerCase() !== 'no'){
                              self.fieldError = "Invalid Column Value [Note- SameCollegeName values like yes or no]";
                              this.isSppiner = false;
                              cb(false)
                              isUpload = false;
                              break;
                        }
                    }else{
                      if(element.SameCollegeName.toString().trim() == "" && element.SameCollegeName.toString().toLowerCase().trim() !== "yes" ){
                      element.SameCollegeName = 'no'
                      }
                    }
                  }
              }else{
                if(element.SameCollegeName !== undefined){
                  if(element.SameCollegeName.toString().trim() == "" && element.SameCollegeName.toString().toLowerCase().trim() !== "yes" ){
                    element.SameCollegeName = 'no'
                  }
                }else{
                    element.SameCollegeName = 'no'
                }
              }
              // if(self.allUniversity.length > 0){              
              //     let getInd = self.allUniversity.findIndex(vaal=>{
              //       return vaal.universityName == element.UniversityName
              //     })
              //     if(getInd > -1){
              //       self.fieldError = `${self.allUniversity[getInd].universityName} is alredy exist in system`;
              //       this.isSppiner = false;
              //       this.$root.$emit('errorUploadAgain')
              //       cb(false)
              //       isUpload = false;                
              //       break;                   
              //     }               
              // }
              if(self.countryOptions.length > 0){
                let countryInd = self.countryOptions.findIndex(val=>{
                  if(val.value !== null){
                    return val.value.name.toLowerCase() == element.Country.toLowerCase()
                  }
                })               
                if(countryInd > -1){
                  countryObj = self.countryOptions[countryInd].value;                   
                  // set State Array
                  var statesArray = State.getStatesOfCountry(self.countryOptions[countryInd].value.isoCode)
                  statesArray.forEach(data=>{
                    importCountry_state.push({value:data,text:data.name})
                  })
                  if(importCountry_state.length > 0){
                      let searchStateInd = importCountry_state.findIndex(stateVal=>{
                        if(stateVal.value !== null){
                          return stateVal.value.name.toLowerCase() == element.State.toLowerCase()
                        }                        
                      })
                      if(searchStateInd > -1){
                           stateObject =  importCountry_state[searchStateInd].value;
                           var cities = City.getCitiesOfState(self.countryOptions[countryInd].value.isoCode,importCountry_state[searchStateInd].value.isoCode)
                            cities.forEach(data=>{
                                importState_city.push({value:data,text:data.name})
                            })
                            if(importState_city.length > 0){
                                let findCity = importState_city.findIndex(cityVal=>{
                                  if(cityVal.value !== null){
                                    return cityVal.value.name.toLowerCase() == element.City.toLowerCase()
                                  }
                                })
                                if(findCity > -1){
                                  cityObject = importState_city[findCity].value; 
                                }else{
                                    self.fieldError = `Invalid city name ${element.State}`;
                                    this.isSppiner = false;
                                    this.$root.$emit('errorUploadAgain')
                                    cb(false)
                                    isUpload = false;                
                                    return; 
                                }
                            }else{
                              self.fieldError = `Invalid city name ${element.State}`;
                              this.isSppiner = false;
                              this.$root.$emit('errorUploadAgain')
                              cb(false)
                              isUpload = false;                
                              return; 
                            }
                      }else{
                        self.fieldError = `Invalid state name ${element.State}`;
                        this.isSppiner = false;
                        this.$root.$emit('errorUploadAgain')
                        cb(false)
                        isUpload = false;                
                        return; 
                      }
                  }else{
                    self.fieldError = `Invalid country name ${element.Country}`;
                    this.isSppiner = false;
                    this.$root.$emit('errorUploadAgain')
                    cb(false)
                    isUpload = false;                
                    return;
                  }
                }else{
                    self.fieldError = `Invalid country name ${element.Country}`;
                    this.isSppiner = false;
                    this.$root.$emit('errorUploadAgain')
                    cb(false)
                    isUpload = false;                
                    return;
                }             
              
              }
              saveData.push({
                'fileData':element,
                'countryObj':countryObj,
                'stateObject':stateObject,
                'cityObject':cityObject
              })
            }
            if(isUpload){                          
              cb(saveData)
            }
          }
          else {
            self.fieldError = "Try Again,Empty file can not be allowed (Note - Do not upload download-sample file direct first save as csv[comma delimited] )";      
            this.isSppiner = false;
            this.$root.$emit('errorUploadAgain')
            return;
          }
      })    
     } 
    },
    importUniversityData(data,cbb){
            let saveObj = {                             
                  'isDeleted':false,
                  'createdAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                  'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                  'universityName':data.fileData.UniversityName,
                  'city':data.cityObject,
                  'state':data.stateObject,
                  'country':data.countryObj,
                  'addressLine1':data.fileData.AddressLine1,
                  'addressLine2':data.fileData.AddressLine2,
                  'pincode':data.fileData.Pincode,
            } 
            inserQuery.addDataToRootCollection(dbCollections.UNIVERSITIES,saveObj,res=>{ 
              let updateId={
                  'id':res.id,
                  'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
              }
              updateQuery.updateRootCollectionDataByDocId(dbCollections.UNIVERSITIES,res.id,updateId,updateRes=>{
                if(data.fileData.SameCollegeName !== undefined){
                    if(data.fileData.SameCollegeName.toLowerCase() == 'yes'){
                          let searchingInd = this.allColleges.findIndex(values=>{
                            return values.collegeName == data.fileData.UniversityName;
                          })
                          if(searchingInd <= -1){
                              let cllgObj = {
                                    'isDeleted':false,
                                    'createdAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                    'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                    'universityName':data.fileData.UniversityName,
                                    'universityId':res.id,
                                    'courses':[],
                                    'collegeName':data.fileData.UniversityName,
                                    'city':data.cityObject,
                                    'state':data.stateObject,
                                    'country':data.countryObj,
                                    'addressLine1':data.fileData.AddressLine1,
                                    'addressLine2':data.fileData.AddressLine2,
                                    'pincode':data.fileData.Pincode,
                              }
                            inserQuery.addDataToRootCollection(dbCollections.COLLEGES,cllgObj,cllgres=>{ 
                                let updateId={
                                    'id':cllgres.id,
                                    'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                }
                                updateQuery.updateRootCollectionDataByDocId(dbCollections.COLLEGES,cllgres.id,updateId,updateCllgRes=>{
                                  this.addedUni.push({
                                     'universityName':data.fileData.UniversityName,
                                      'id':res.id,
                                      'sameNameCollege':cllgres.id,
                                      'obj':cllgres
                                  })   
                                  this.getColleges();         
                                  cbb(true)  
                                })
                            })    
                          }else{
                            this.addedUni.push({
                                     'universityName':data.fileData.UniversityName,
                                      'id':res.id,
                                      'sameNameCollege':this.allColleges[searchingInd].id,
                                      'obj':this.allColleges[searchingInd]
                            })
                            cbb(true) 
                          }
                    }else{
                      this.addedUni.push({
                          'universityName':data.fileData.UniversityName,
                          'id':res.id,
                          'sameNameCollege':""
                      })
                      cbb(true)
                    }
                }else{
                    this.addedUni.push({
                        'universityName':data.fileData.UniversityName,
                        'id':res.id,
                        'sameNameCollege':""
                    })
                    cbb(true) 
                }          
              })
            })        
    },
    matchUniversityData(row,data,res){
      if(row){
          let sendRes = {
            'sameUni':false,
            'sameCllg':false
          }      
          if(row.universityName.toLowerCase().trim() == data.fileData.UniversityName.toLowerCase().trim()){
              sendRes.sameUni = true;
              if(row.sameNameCollege !== "" && row.sameNameCollege !== null){
                sendRes.sameCllg = true;
              }
              res(sendRes)
          }else{
              res(sendRes)
          }
      }else{
        res(true)
      }
    },   
    importCollgeData(row,data,cbb){
      if(this.allColleges.length <= 0){ //Then add first college
        let cllgObj = {
                    'isDeleted':false,
                    'createdAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                    'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                    'universityName':data.fileData.UniversityName,
                    'universityId':row.id,
                    'courses':[],
                    'collegeName':data.fileData.UniversityName,
                    'city':data.cityObject,
                    'state':data.stateObject,
                    'country':data.countryObj,
                    'addressLine1':data.fileData.AddressLine1,
                    'addressLine2':data.fileData.AddressLine2,
                    'pincode':data.fileData.Pincode,
              }
        inserQuery.addDataToRootCollection(dbCollections.COLLEGES,cllgObj,cllgres=>{ 
              let updateId={
                  'id':cllgres.id,
                  'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
              }
              updateQuery.updateRootCollectionDataByDocId(dbCollections.COLLEGES,cllgres.id,updateId,updateCllgRes=>{
                let updateSameCllg = this.addedUni.findIndex(values=>{
                  return values.id == row.id
                })
                if(updateSameCllg > -1){
                    this.addedUni[updateSameCllg].sameNameCollege = cllgres.id;
                    this.addedUni[updateSameCllg].obj = cllgres  
                }         
                this.getColleges();         
                cbb(true)         
              })
          })
        cbb(false)
      }else{
          let searchingInd = this.allColleges.findIndex(values=>{
            return values.collegeName == data.fileData.UniversityName
          })
          if(searchingInd <= -1){
              let cllgObj = {
                    'isDeleted':false,
                    'createdAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                    'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                    'universityName':data.fileData.UniversityName,
                    'universityId':row.id,
                    'courses':[],
                    'collegeName':data.fileData.UniversityName,
                    'city':data.cityObject,
                    'state':data.stateObject,
                    'country':data.countryObj,
                    'addressLine1':data.fileData.AddressLine1,
                    'addressLine2':data.fileData.AddressLine2,
                    'pincode':data.fileData.Pincode,
              }
            inserQuery.addDataToRootCollection(dbCollections.COLLEGES,cllgObj,cllgres=>{ 
                let updateId={
                    'id':cllgres.id,
                    'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                }
                updateQuery.updateRootCollectionDataByDocId(dbCollections.COLLEGES,cllgres.id,updateId,updateCllgRes=>{
                  let updateSameCllg = this.addedUni.findIndex(values=>{
                    return values.id == row.id
                  })
                  if(updateSameCllg > -1){
                     this.addedUni[updateSameCllg].sameNameCollege = cllgres.id;
                     this.addedUni[updateSameCllg].obj = cllgres  
                  }         
                  this.getColleges();         
                  cbb(true)         
                })
            })    
          }else{
            cbb(true) 
          }
      }
    },
    addUniversityData(data,cbb){    
      var self = this;
      if(data !== undefined){

        if(this.addedUni.length > 0)
        {
            let fileArry = this.addedUni; //EACH UNIVERSITTY CHECK DATA NAME IS NOT SAME
            let count = 0;
            const uploadFileFunction = (row) => {
               if(count >= fileArry.length){  
                 //IF NOT FOUND SAME UNIVERSITY THEN
                 this.importUniversityData(data,res=>{
                  cbb(res)
                  return;
                })                     
               }else{
                self.matchUniversityData(row,data,(saveRes)=>{
                    if(saveRes.sameUni !== false)
                    {     
                        if(data.fileData.SameCollegeName.toLowerCase() == 'yes'){
                            self.importCollgeData(row,data,savedCllg=>{
                              cbb(savedCllg)  
                              return;
                            })
                        }else{   
                          cbb(saveRes)  
                          return;
                        }
                    }
                    else
                    {
                        count += 1;
                        uploadFileFunction(fileArry[count]);
                    }
                });              
               }
            }
            uploadFileFunction(fileArry[count]);
        }
        //IF ADD FIRST UNIVERSITY
        else
        {
          this.importUniversityData(data,res=>{
            cbb(res)
          })
        }  
      }else{
        cbb(false)
      }
    },
    saveUniversityData(data,res){
        if(data && data.length > 0){
          // console.log(data,"datadata",data.length,"data") //ALL RECORDS OF CSV FILE
          let count = 0;
          let fileArry = data;
          const uploadFileFunction = (row) => {                             
              this.addUniversityData(row,(saveRes)=>{                
                  if(count >= Number(fileArry.length-1)){
                    res(true)
                    return
                  }else{ 
                  if(saveRes !== false)
                  {
                    count += 1;
                    uploadFileFunction(fileArry[count]);
                  }
                  else
                  {
                    res(saveRes)
                  }
                  }
              });              
          }
          uploadFileFunction(fileArry[count]);            
        } 
    },
    importCsvFile(event){
       if(event.length > 0){
        this.isSppiner = true;
          let count = 0;         
            const uploadFileFunction = (row) => {  
                this.uploadImportFile(row,(saveRes)=>{                   
                    if(saveRes == false)
                    {
                      this.$root.$emit('errorUploadAgain')
                      this.isSppiner = false;
                      return;
                    }
                    else{    
                      this.saveUniversityData(saveRes,saveCb=>{
                          if(count >= parseInt(Number(event.length)-1))
                          {      
                                this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Document Add Successfully',
                                    icon: 'CoffeeIcon',
                                    variant: 'success',                               
                                },
                                })  
                                this.$root.$emit('importFileDone')                        
                                this.isSppiner = false;
                                this.refreshPage(()=>{
                                });                               
                                return;                  
                          }
                          else
                          {                        
                            count += 1;
                            uploadFileFunction(event[count]);
                          }
                      })  
                  
                  }
                });              
            }
            uploadFileFunction(event[count]);
      }    
    }
  },
  beforeDestroy(){
    this.$root.$off('exportUniversity')
    console.log('COMPONENT UNMOUNTED SUCCESSFULLT FROM UNIVERSITY LIST');
    this.fetchMoreDataStop = true;
    this.universityArray = [];
  },
  computed:{
    // duplicateUniversityName()
    // {
    //   var self = this
    //   var found = false

    //   self.universities.forEach((data,index)=>{
    //     if(data.toLowerCase().trim()==self.universityName.toLowerCase().trim())
    //     {
    //       found = true
    //     }
    //   })

    //   return found;
    // },
    // duplicateCollgeName()
    // {
    //   var self = this
    //   var found = false

    //   self.college.forEach((data,index)=>{
    //     if(data.toLowerCase().trim()==self.universityName.toLowerCase().trim() && self.isCollegeNameSame == true)
    //     {
    //       found = true
    //     }
    //   })

    //   return found;
    // },
    // duplicateEditUniversityName()
    // {
    //   var self = this
    //   var found = false

    //   self.allUniData.forEach((data,index)=>{
    //     if(data.name.toLowerCase().trim()==self.universityName.toLowerCase().trim() && index != self.selectedUniversity.index)
    //     {
    //       // found = true;
    //       if(Number(index + 1) == self.allUniData){
    //         return;
    //       }
    //       if(data.id.toString() == self.selectedUniversity.item.id.toString()){
    //         found = false;
    //       }else{
    //       found = true;
    //       }
    //       // found = true
    //     }
    //   })

    //   return found;
    // }
  }
}
</script>
<style>
.focusRemoveToggle:focus {
  outline: none !important;
}
.table-disbled-row {
  cursor: default !important;
}
</style>
<style scoped>
.card .card-body h4.card-title{
  border-bottom:none !important;
  margin: 0px -20px 0px -14px !important;
}
.tableFilters .form-group{
  margin-bottom: 0px !important;
}
</style>

<style>
.position-relative.table-responsive thead tr th{
  white-space: nowrap !important;
}
/* .position-relative.table-responsive tbody tr td{
  white-space: nowrap !important;
} */

.s_modal_title{
    width: 100%;
    
    font-weight: 600;
    font-size: 20px;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
