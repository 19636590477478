import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//firebase
import firebase from "@/utils/firebaseInit"
import firebaseTimeStamp from "firebase"
import {dbCollections} from "@/utils/firebaseCollection"
import { getCollectionData } from '@/utils/dynamicGetDataQuery'
// import { getCollectionDataTypesense, updateManyTypesenseCollection, updateTypesenseCollection } from '@/utils/Typesense/queries/index.js'
import { updateRootCollectionDataByDocId } from '@/utils/FirebaseQueries/updateQueries/updateQueries'
import { getCollectionDataTypesense } from '@/utils/Typesense/queries'
const db = firebase.firestore()
const months=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'no', sortable: false },
    { key: 'university_name', sortable: true },
    { key: 'address', sortable: false },
    { key: 'city', sortable: true },
    { key: 'state', sortable: true },
    { key: 'country', sortable: true },
    { key: 'actions' }
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('')
  const isSortDirDesc = ref(null)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const isSpinner = ref(null)
  const sortContect = ref(null)
  const timeFnction = ref(null)


  const filterCountry = ref(null)
  const filterState = ref(null)
  const filterCity = ref(null)
  const allUniversitys = ref(null)

  const universityArray = ref([])
  const isFetching = ref(false)
  const previuosDisabled = ref(false)
  const nextDisabled = ref(false)
  const firstDoc = ref(null)
  const lastDoc = ref(null)
  const docCategoryTable  = ref(null)
  const fetchMoreDataStop = ref(false)
  
  const dataMeta = computed(() => {
      const localItemsCount = docCategoryTable.value ? docCategoryTable.value.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalUsers.value,
      }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }
  
  watch([currentPage], () => {
    refetchData()
  })
  watch([perPage,filterCountry,filterState,filterCity], () => {
    fetchUniversityFromTypesense(true,false,()=>{refetchData()})
  })

  
  // var usersList = []
  // var users = []
  // const fetchUsers = (ctx, callback) => {
  //   users = []
  //   if(usersList.length==0 && isSpinner.value)
  //   {
  //     db
  //       .collection(dbCollections.UNIVERSITIES)
  //       .orderBy('createdAt')
  //       .get()
  //       .then(querySnapshot=>{
  //         if(!querySnapshot.empty)
  //         {
  //           var startIndex = (currentPage.value-1)*perPage.value+1
  //           var endIndex = startIndex+perPage.value-1
  //           var index = 1
  //           var dataIndex = 1
  //           querySnapshot.forEach(doc=>{

  //             //Turn isDeleted Flag = false
  //             // db
  //             //   .collection(dbCollections.UNIVERSITIES)
  //             //   .doc(doc.id)
  //             //   .update({
  //             //     isDeleted:false
  //             //   })
  //             //   .catch(error => console.log(error))

  //             // if(!doc.data().isDeleted)
  //             // {
  //               usersList.push(doc.data())

  //               usersList[usersList.length-1].exportAddress1=`${doc.data().addressLine1}.`
  //               usersList[usersList.length-1].exportAddress2=`${doc.data().addressLine2?' ':''}${doc.data().addressLine2}`
  //               usersList[usersList.length-1].university_name=doc.data().universityName
  //               usersList[usersList.length-1].isInvoiceGenerated=doc.data().isInvoiceGenerated != undefined ? doc.data().isInvoiceGenerated : false
  //               usersList[usersList.length-1].address=`${doc.data().addressLine1} ${doc.data().addressLine2?', ':''}${doc.data().addressLine2}, ${doc.data().pincode}`
  //             // }
  //             if(dataIndex>=startIndex && dataIndex<=endIndex)
  //             {
  //               users.push({
  //                 'id':doc.data().id,
  //                 'isDeleted':doc.data().isDeleted||false,
  //                 'exportAddress1':`${doc.data().addressLine1}.`,
  //                 'exportAddress2':`${doc.data().addressLine2?' ':''}${doc.data().addressLine2}`, 
  //                 'pincode':`${doc.data().pincode}`,  
  //                 'university_name':doc.data().universityName,
  //                 'address':`${doc.data().addressLine1} ${doc.data().addressLine2?', ':''}${doc.data().addressLine2}, ${doc.data().pincode}`,
  //                 'city':doc.data().city,
  //                 'state':doc.data().state,
  //                 'country':doc.data().country,
  //                 'isInvoiceGenerated':doc.data().isInvoiceGenerated != undefined ? doc.data().isInvoiceGenerated : false
  //               })
  //               dataIndex++;
  //             }
  //             if(index==querySnapshot.size)
  //             {
  //               totalUsers.value = usersList.length
  //               isSpinner.value=false
  //               allUniversitys.value = usersList   
  //               callback(users)
  //             }
  //             index++;
  //           })
  //         }
  //         else
  //         {
  //           totalUsers.value = querySnapshot.size
  //           isSpinner.value=false
  //           callback(users)
  //         }
  //       })
  //       .catch(error => {
  //         isSpinner.value=false
  //         console.log(error)
  //       })
  //   }
  //   else
  //   {
  //     console.log("Already has list data")
  //     var startIndex = (currentPage.value-1)*perPage.value+1
  //     var endIndex = startIndex+perPage.value-1
  //     var index = 1
  //     // var dataIndex = 1
  //     if(usersList.length==0)
  //     {
  //       totalUsers.value = usersList.length
  //       isSpinner.value=false
  //       callback(users)
  //       return;
  //     }

  //     if(isSortDirDesc.value==false)
  //     {
  //       console.log("Asc")
  //       if(sortBy.value=='city')
  //       {
  //         usersList.sort((a, b) => (a.city.name > b.city.name ? 1 : -1))//Asc
  //       }
  //       else if(sortBy.value=='state')
  //       {
  //         usersList.sort((a, b) => (a.state.name > b.state.name ? 1 : -1))//Asc
  //       }
  //       else if(sortBy.value=='country')
  //       {
  //         usersList.sort((a, b) => (a.country.name > b.country.name ? 1 : -1))//Asc
  //       }
  //       else
  //       {
  //         usersList.sort((a, b) => (a[sortBy.value] > b[sortBy.value] ? 1 : -1))//Asc
  //       }
  //     }
  //     else if(isSortDirDesc.value==true)
  //     {
  //       console.log("Desc")
  //       if(sortBy.value=='city')
  //       {
  //         usersList.sort((a, b) => (a.city.name > b.city.name ? -1 : 1))//Desc
  //       }
  //       else if(sortBy.value=='state')
  //       {
  //         usersList.sort((a, b) => (a.state.name > b.state.name ? -1 : 1))//Desc
  //       }
  //       else if(sortBy.value=='country')
  //       {
  //         usersList.sort((a, b) => (a.country.name > b.country.name ? -1 : 1))//Desc
  //       }
  //       else
  //       {
  //         usersList.sort((a, b) => (a[sortBy.value] > b[sortBy.value] ? -1 : 1))//Desc
  //       }
  //     }

  //     usersList.forEach(data=>{
  //       if(searchQuery.value=='' && filterCountry.value==null && filterState.value==null && filterCity.value==null)
  //       {
  //         if(index>=startIndex && index<=endIndex)
  //         {
  //           users.push({
  //             'id':data.id,
  //             'isDeleted':data.isDeleted||false,
  //             'exportAddress1':`${data.addressLine1}.`,
  //             'exportAddress2':`${data.addressLine2?' ':''}${data.addressLine2}`, 
  //             'pincode':`${data.pincode}`,  
  //             'university_name':data.universityName,
  //             'isInvoiceGenerated':data.isInvoiceGenerated !== undefined ? data.isInvoiceGenerated : false,
  //             'address':`${data.addressLine1} ${data.addressLine2?', ':''}${data.addressLine2}, ${data.pincode}`,
  //             'city':data.city,
  //             'state':data.state,
  //             'country':data.country
  //           })
  //           // dataIndex++;
  //         }
  //         if(index==usersList.length)
  //         {
  //           // if(currentPage.value > 1 && users.length==0)
  //           // {
  //           //   currentPage.value -= currentPage.value
  //           // }
  //           totalUsers.value = usersList.length
  //           isSpinner.value=false
  //           allUniversitys.value = usersList   
  //           callback(users)
  //         }
  //         index++;
  //       }
  //       else if(data.isDeleted !== undefined)
  //       {
  //         let findIndex = users.findIndex((ele)=>{return ele.id === data.id});
  //         if(findIndex !== -1) {
  //           let findIndex1 = usersList.findIndex((ele)=>{return ele.id === data.id});
  //           let address;
  //           let updateObject = {
  //             'id':data.id,
  //             'isDeleted':data.isDeleted||false,
  
  //             'university_name':data.universityName,
  //             'isInvoiceGenerated':data.isInvoiceGenerated !== undefined ? data.isInvoiceGenerated : false,
  //             'address':address,
  //             'city':data.city,
  //             'state':data.state,
  //             'country':data.country
  //           }
  //           if(findIndex1 == -1) {
  //             address = `${data.addressLine1} ${data.addressLine2?', ':''}${data.addressLine2}, ${data.pincode}`
  //             users[findIndex] = {...users[findIndex],...updateObject}
  //           } else {
  //             address = `${usersList[findIndex1].addressLine1} ${usersList[findIndex1].addressLine2?', ':''}${usersList[findIndex1].addressLine2}, ${usersList[findIndex1].pincode}`
  //             users[findIndex] = {...users[findIndex],...usersList[findIndex1],'address':address}
  //           }
  //         } else {
  //           let address = `${data.addressLine1} ${data.addressLine2?', ':''}${data.addressLine2}, ${data.pincode}`
  //           users.push({
  //             'id':data.id,
  //             'isDeleted':data.isDeleted||false,
  
  //             'university_name':data.universityName,
  //             'isInvoiceGenerated':data.isInvoiceGenerated !== undefined ? data.isInvoiceGenerated : false,
  //             'address':address,
  //             'city':data.city,
  //             'state':data.state,
  //             'country':data.country
  //           })
  //         }
          
  //         if(index==usersList.length)
  //         {
  //           var finalArr = users;
  //           var tmp = [];

  //           var dataIndex=1
  //           if(searchQuery.value!='')
  //           {
  //             finalArr.forEach(UD=>{
  //               if(
  //                 searchQuery.value && UD.university_name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
  //                 searchQuery.value && UD.city.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
  //                 searchQuery.value && UD.country.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
  //                 searchQuery.value && UD.state.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
  //                 searchQuery.value && UD.address.toLowerCase().includes(searchQuery.value.toLowerCase())
  //               )
  //               {
  //                 tmp.push(UD)
  //               }
  //             })
  //             finalArr=tmp
  //             tmp=[]
  //           }
  //           //Country Filter
  //           if(filterCountry.value!=null)
  //           {
  //             finalArr.forEach(UD=>{
  //               if(filterCountry.value.name == UD.country.name)
  //               {
  //                 tmp.push(UD)
  //               }
  //             })
  //             finalArr=tmp
  //             tmp=[]
  //           }
  //           //State Filter
  //           if(filterState.value!=null)
  //           {
  //             finalArr.forEach(UD=>{
  //               if(filterState.value.name == UD.state.name)
  //               {
  //                 tmp.push(UD)
  //               }
  //             })
  //             finalArr=tmp
  //             tmp=[]
  //           }
  //           //City Filter
  //           if(filterCity.value!=null)
  //           {
  //             finalArr.forEach(UD=>{
  //               if(filterCity.value.name == UD.city.name)
  //               {
  //                 tmp.push(UD)
  //               }
  //             })
  //             finalArr=tmp
  //             tmp=[]
  //           }

  //           //Final Table Data
  //           finalArr.forEach(FD=>{
  //             if(dataIndex>=startIndex && dataIndex<=endIndex)
  //             {
  //               tmp.push(FD)
  //             }
  //             dataIndex++
  //           })
  //           // if(currentPage.value > 1 && users.length==0)
  //           // {
  //           //   currentPage.value -= currentPage.value
  //           // }
  //           totalUsers.value = finalArr.length
  //           isSpinner.value=false
  //           allUniversitys.value = usersList   
  //           callback(tmp)
  //         }
  //         index++;
  //       }
  //       else
  //       {
  //         isSpinner.value=false
  //       }
  //     })
  //   }

  //   // for (let index = 0; index < 10; index++) {
  //   //   users.push({
  //   //     'No':index,
  //   //     'Date of Inquiry':`Date ${index}`,
  //   //     'Student Name':`Name ${index}`,
  //   //     'Phone no.':9462489464654,
  //   //     'College Name.':`Test`,
  //   //     'Branch.':`abcd`,
  //   //     'CGPA(%).':(index+1*(Math.random()*100)%100).toFixed(2),
  //   //     'actions':`<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" > Create Account </b-button> <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" > Create New Inquiry </b-button>`
  //   //   })
  //   // }
  //   // callback(users)
  // }
  const fetchUniversityFromTypesense = (resetResult = true,manageData=false,callback) => {
    try {
      if(resetResult){
        isFetching.value = true;
      }
      if(manageData === true) {
        currentPage.value = 1;
        sortBy.value = '';
        isSortDirDesc.value = null;
        sortContect.value = null;
      }
      let searchParameters = {
        collectionName: `${dbCollections.UNIVERSITIES}`,
        search: {
            q:"*",
            page: currentPage.value,
            per_page: perPage.value,
        }
      }

      let filter = ``

      if (filterCountry.value !== null && filterCountry.value !== '') {
          if(filter !== '') {
              filter += ` && `
          }
          filter += `country.name:=${filterCountry.value.name}`
      }
      if (filterState.value !== null && filterState.value !== '') {
          if(filter !== '') {
              filter += ` && `
          }
          filter += `state.name:=${filterState.value.name}`
      }
      if (filterCity.value !== null && filterCity.value !== '') {
          if(filter !== '') {
              filter += ` && `
          }
          filter += `city.name:=${filterCity.value.name}`
      }
      if(filter !== '' && filter !== null) {
        searchParameters.search.filter_by = filter;
      }
      if (sortContect.value) {
        searchParameters.search.sort_by = `${(sortContect.value.sortBy && sortContect.value.sortBy !== '') ? `${sortContect.value.sortBy}: ${sortContect.value.sortDesc == false ? 'asc' : 'desc'}` : ''}`;
      }
      if(searchQuery.value !== '' && searchQuery.value !== null) {
        searchParameters.search.q = searchQuery.value;
        searchParameters.search.query_by = 'universityName,country.name,state.name,city.name';
      }
        getCollectionDataTypesense(searchParameters).then((result)=>{
            if(result.hits.length) {
              totalUsers.value = result.found
                const arrayData = result.hits.map(x => {
                    const row = x.document;

                    return {
                      'id':row.id,
                      'isDeleted':row.isDeleted||false,
                      'exportAddress1':`${row.addressLine1}.`,
                      'exportAddress2':`${row.addressLine2?' ':''}${row.addressLine2}`, 
                      'pincode':`${row.pincode}`,  
                      'university_name':row.universityName,
                      'address':`${row.addressLine1} ${row.addressLine2?', ':''}${row.addressLine2}, ${row.pincode}`,
                      'city':row.city,
                      'state':row.state,
                      'country':row.country,
                      'isInvoiceGenerated':row.isInvoiceGenerated != undefined ? row.isInvoiceGenerated : false
                    };
                });
                universityArray.value = arrayData;
                isFetching.value = false;
                callback(arrayData)
            } else {
              totalUsers.value = 0;
              universityArray.value = [];
              callback([])
              isFetching.value = false;
            }
        }).catch((error) => {
          universityArray.value = [];
          totalUsers.value = 0;
          callback([])
          console.error(error)
          isFetching.value = false;
        })
    } catch (error) {
      universityArray.value = [];
      totalUsers.value = 0;
      callback([])
      console.error(error)
    }
  }
  const handlePageChange = (newPage) => {
      currentPage.value = newPage;
    
      fetchUniversityFromTypesense(false,false,()=>{refetchData()})
  }
  function handleSearch() {
    clearTimeout(timeFnction.value);
    timeFnction.value = setTimeout(() => {
      fetchUniversityFromTypesense(false,false,()=>{refetchData()});
    }, 1000);
  }
  const handleSortChange = (context) => {
    let sortFieldObject = {
        university_name : "universityName",
        address : "",
        city : "city.name",
        state : "state.name", 
        country : "country.name", 
        actions : "", 
    }
    const {sortBy,sortDesc} = context
    sortContect.value = {sortBy:sortFieldObject[sortBy],sortDesc};
    currentPage.value = 1;
    fetchUniversityFromTypesense(false,false,()=>{refetchData()})
  }

  function fetchUniversity(direction = '',refetchData = false) {
    try {
      if(refetchData == false) {
        isSpinner.value = true
          isFetching.value = true;
          universityArray.value = []
        }
        let whereQueries = []

        let limit = 100
        let orderByValue = {field: "createdAt",value: 1}
        let refDoc;
        let refDoc1;
        let limitToLast = 0;
        if(direction == 'next') {
            orderByValue = {field: "createdAt",value: 1}
            refDoc = {
                doc: lastDoc.value
            }
        } else if(direction == 'previous') {
            orderByValue = {field: "createdAt",value: 1}
            refDoc1 = {
              doc: firstDoc.value
            }
            limitToLast = limit
        }
        getCollectionData(`${dbCollections.UNIVERSITIES}`,whereQueries, orderByValue,limit,'','',refDoc?.doc || '',refDoc1?.doc || '',limitToLast)
          .then((querySnapshot) => {

            if(querySnapshot.empty == true) {
              isSpinner.value = false;
              isFetching.value = false;
              if(direction == 'next') {
                nextDisabled.value = true;
                lastDoc.value = '';
              } else if(direction == 'previous') {
                previuosDisabled.value = true;
                firstDoc.value = '';
              }
              return;
            }
            // universityArray.value = [];
            const docs =  querySnapshot.docs?.map((x) => ({...x.data(), id: x.id}));
            if(direction == 'next') {
              lastDoc.value = querySnapshot.docs?.[querySnapshot.docs.length - 1];
            } else if(direction == 'previous') {
              firstDoc.value = querySnapshot.docs?.[0];
            }
            let users = [];
            docs.forEach(async(doc)=>{
                const userDetail = {
                  'id':doc.id,
                  'isDeleted':doc.isDeleted||false,
                  'exportAddress1':`${doc.addressLine1}.`,
                  'exportAddress2':`${doc.addressLine2?' ':''}${doc.addressLine2}`, 
                  'pincode':`${doc.pincode}`,  
                  'university_name':doc.universityName,
                  'address':`${doc.addressLine1} ${doc.addressLine2?', ':''}${doc.addressLine2}, ${doc.pincode}`,
                  'city':doc.city,
                  'state':doc.state,
                  'country':doc.country,
                  'isInvoiceGenerated':doc.isInvoiceGenerated != undefined ? doc.isInvoiceGenerated : false
                };
            
                users.push(userDetail);

                if(users.length === docs.length) {
                  universityArray.value = [...universityArray.value,...users]
                  lastDoc.value = querySnapshot.docs[querySnapshot.docs.length - 1];
                  firstDoc.value = querySnapshot.docs[0];
                  totalUsers.value = universityArray.value.length
                  isSpinner.value = false;
                  isFetching.value = false;
                  if (nextDisabled.value === false && fetchMoreDataStop.value === false) {
                    fetchUniversity('next',true);
                  }
                }
              })
          })
          .catch((error) => {
              isFetching.value = false;
              isSpinner.value = false;
              console.error("Error in fetchUniversity", error);
          });
    } catch (error) {
        isFetching.value = false;
        console.error("Error in fetchUniversity", error);
    }
  }

  const filteredUniversityList = computed(() => {
    let finalArr = universityArray.value;
    let tmp=[]
    if(searchQuery.value!='')
    {
      finalArr.forEach(UD=>{
        if(
          searchQuery.value && UD.university_name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          searchQuery.value && UD.city.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          searchQuery.value && UD.country.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          searchQuery.value && UD.state.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          searchQuery.value && UD.address.toLowerCase().includes(searchQuery.value.toLowerCase())
        )
        {
          tmp.push(UD)
        }
      })
      finalArr=tmp
      tmp=[]
    }
    //Country Filter
    if(filterCountry.value!=null)
    {
      finalArr.forEach(UD=>{
        if(filterCountry.value.name == UD.country.name)
        {
          tmp.push(UD)
        }
      })
      finalArr=tmp
      tmp=[]
    }
    //State Filter
    if(filterState.value!=null)
    {
      finalArr.forEach(UD=>{
        if(filterState.value.name == UD.state.name)
        {
          tmp.push(UD)
        }
      })
      finalArr=tmp
      tmp=[]
    }
    //City Filter
    if(filterCity.value!=null)
    {
      finalArr.forEach(UD=>{
        if(filterCity.value.name == UD.city.name)
        {
          tmp.push(UD)
        }
      })
      finalArr=tmp
      tmp=[]
    }
    return finalArr
  })

  const createUniversity = (data, callback)=>{
    db
      .collection(dbCollections.UNIVERSITIES)
      .add({
        id:'',
        isDeleted:false,
        createdAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
        updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),

        universityName:data.universityName,
        addressLine1:data.addressLine1,
        addressLine2:data.addressLine2,
        country:data.country,
        state:data.state,
        city:data.city,
        pincode:data.pincode,
        isInvoiceGenerated:data.isInvoice
      })
      .then((docRef)=>{
        db
          .collection(dbCollections.UNIVERSITIES)
          .doc(docRef.id)
          .update({
            id:docRef.id,
            updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
          })
          .then(()=>{
            let updateObject = {
              'id':docRef.id,
              'isDeleted':false,

              'universityName':data.universityName,
              'university_name':data.universityName,
              'exportAddress1':data.addressLine1,
              'exportAddress2':`${data.addressLine1?' ':''}${data.addressLine2}`, 
              'addressLine1':data.addressLine1,
              'addressLine2':data.addressLine2,
              'address':`${data.addressLine1} ${data.addressLine2?', ':''}${data.addressLine2}, ${data.pincode}`,
              'pincode':data.pincode,
              'city':data.city,
              'state':data.state,
              'country':data.country,
              'isInvoiceGenerated':data.isInvoice != undefined ? data.isInvoice : false
            }
            // universityArray.value.push({
              if(currentPage.value == 1) {
                totalUsers.value = totalUsers.value + 1;
                if(sortContect.value !== null) {
                  fetchUniversityFromTypesense(false,true,()=>{
                    let index = universityArray.value.findIndex((e)=>e.id === docRef.id);
                    if(index == -1) {
                      universityArray.value.unshift({
                        ...updateObject
                      })
                    }
                    totalUsers.value = totalUsers.value + 1;
                    refetchData()
                  })
                } else {
                  universityArray.value.unshift({
                    ...updateObject
                  })
                }
              } else {
                fetchUniversityFromTypesense(false,true,()=>{
                  let index = universityArray.value.findIndex((e)=>e.id === docRef.id);
                  if(index == -1) {
                    universityArray.value.unshift({
                     ...updateObject
                    })
                  }
                  totalUsers.value = totalUsers.value + 1;
                  refetchData()
                })
              }
            if(data.isCollegeNameSame)
            {
              db
                .collection(dbCollections.COLLEGES)
                .add({
                  id:'',
                  isDeleted:false,
                  createdAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                  updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),

                  collegeName:data.universityName,
                  universityName:data.universityName,
                  universityId:docRef.id,
                  courses:[],
                  city:data.city,
                  state:data.state,
                  country:data.country,

                  addressLine1:data.addressLine1,
                  addressLine2:data.addressLine2,
                  pincode:data.pincode,
                })
                .then((docRef2)=>{
                  db
                    .collection(dbCollections.COLLEGES)
                    .doc(docRef2.id)
                    .update({
                      id:docRef2.id,
                      updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
                    })
                    .then(()=>{
                    })
                    .catch(error =>{
                      console.log('College Not Created',error)
                    })
                  
                  callback(true)
                  refetchData()
                })
                .catch(error =>{
                  console.log(error)
                  callback(true)
                  refetchData()
                })
            }
            else
            {
              callback(true)
              refetchData()
            }
            
          })
          .catch(error =>{
            console.log(error)
            callback(false)
          })
      })
      .catch(error =>{
        console.log(error)
        callback(false)
      })
  }

  const refreshPage = (callback)=>{
    isSpinner.value = true;
    refetchData()
    callback(true)
  }

  const editUniversity = (data, callback)=>{
    var index = (currentPage.value-1) * perPage.value + data.index + 1
    db
      .collection(dbCollections.UNIVERSITIES)
      .doc(data.id)
      .update({
        updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),

        universityName:data.universityName,
        addressLine1:data.addressLine1,
        addressLine2:data.addressLine2,
        country:data.country,
        state:data.state,
        city:data.city,
        pincode:data.pincode,
        isInvoiceGenerated:data.isInvoice
      })
      .then(()=>{
        let fIndex = universityArray.value.findIndex((e)=>e.id == data.id);
        if(fIndex !== -1) {
          universityArray.value[fIndex]={
            ...universityArray.value[fIndex],
            'id':data.id,
            'isDeleted':false,
            'universityName':data.universityName,
            'addressLine1':data.addressLine1,
            'addressLine2':data.addressLine2,
            'pincode':data.pincode,
            'city':data.city,
            'state':data.state,
            'country':data.country,
            'isInvoiceGenerated':data.isInvoice,
            'address':`${data.addressLine1} ${data.addressLine2?', ':''}${data.addressLine2}, ${data.pincode}`,
          }
          callback(true)
        }
        refetchData()
      })
      .catch(error =>{
        console.log(error)
        callback(false)
      })
  }
  const editUniversityAndCollege = (data, callback)=>{
    var index = (currentPage.value-1) * perPage.value + data.index + 1
    db.collection(dbCollections.UNIVERSITIES).doc(data.id).update({
        updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
        universityName:data.universityName,
        addressLine1:data.addressLine1,
        addressLine2:data.addressLine2,
        country:data.country,
        state:data.state,
        city:data.city,
        pincode:data.pincode,
        isInvoiceGenerated:data.isInvoice
      })
      .then((docRef)=>{    
        let fIndex = universityArray.value.findIndex((e)=>e.id == data.id);
        universityArray.value[fIndex]={
          ...universityArray.value[fIndex],
          'id':data.id,
          'isDeleted':false,
          'universityName':data.universityName,
          'addressLine1':data.addressLine1,
          'addressLine2':data.addressLine2,
          'pincode':data.pincode,
          'city':data.city,
          'state':data.state,
          'country':data.country,
          'isInvoiceGenerated':data.isInvoice,
          'address':`${data.addressLine1} ${data.addressLine2?', ':''}${data.addressLine2}, ${data.pincode}`,
        }       
            if(data.isCollegeNameSame)
            {
              db
                .collection(dbCollections.COLLEGES)
                .add({
                  id:'',
                  isDeleted:false,
                  createdAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                  updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),

                  collegeName:data.universityName,
                  universityName:data.universityName,
                  universityId:data.id,
                  courses:[],
                  city:data.city,
                  state:data.state,
                  country:data.country,

                  addressLine1:data.addressLine1,
                  addressLine2:data.addressLine2,
                  pincode:data.pincode,
                })
                .then((docRef2)=>{
                  db
                    .collection(dbCollections.COLLEGES)
                    .doc(docRef2.id)
                    .update({
                      id:docRef2.id,
                      updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                    })
                    .then(()=>{
                    })
                    .catch(error =>{
                      console.log('College Not Created',error)
                    })
                  
                  callback(true)
                  refetchData()
                })
                .catch(error =>{
                  console.log(error)
                  callback(true)
                  refetchData()
                })
            }
            else
            {
              callback(true)
              refetchData()
            }
            
          })
          .catch(error =>{
            console.log(error)
            callback(false)
          })
      
  }
  const deleteField = (ctx, callback)=>{
    function updateFunction(docRef) {
      return new Promise((resolve, reject) => {
        db.collection(dbCollections.COLLEGES)
          .doc(docRef.data().id)
          .update({ isDeleted: true, updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp()})
          .then(() => {
            db.collection(dbCollections.COURSES)
            .where('collegeId', '==', docRef.data().id)
            .get()
            .then((querySnapshot) => {
                let count = 0
                const updateFunction = (doc) => {
                  if(count >= querySnapshot.docs.length) {
                    return;
                  } else {
                    updateRootCollectionDataByDocId(dbCollections.COURSES,doc.id,{ isDeleted: true,updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp()},()=>{
                      count++;
                      updateFunction(querySnapshot.docs[count]);
                    })
                  }
                }
                updateFunction(querySnapshot.docs[count]);
            })
            // .then(()=> {
            //   console.log('DONE');
              // console.log('FIREBASE UPDATE SUCCESSFUKLLY STEP 1')
              //   let serchQuery = {
              //     collectionName: `${dbCollections.COURSES}`,
              //     search: {
              //         q: "*",
              //         filter_by: `collegeId:=${docRef.data().id}`,
              //         per_page: 250,
              //     }
              //   };
              //   getCollectionDataTypesense(serchQuery).then((response)=>{
              //     if(response.hits.length) {
              //       console.log(response.hits);
              //       let count = 0
              //       const updateFunction = (hit) => {
              //         if(count >= response.hits.length) {
              //           return;
              //         } else {
              //           updateTypesenseCollection(dbCollections.COURSES,{isDeleted:true},hit.document.id).then((updatedDocument)=>{
              //             console.log(`Document ${updatedDocument.id} updated successfully STEP 2`);
              //             count++;
              //             updateFunction(response.hits[count]);
              //           })
              //           .catch(error => {
              //             console.error(`Error updating document ${hit.id}:`, error);
              //             count++;
              //             updateFunction(response.hits[count]);
              //           });
              //         }
              //       }
              //       updateFunction(response.hits[count]);
              //     }
              //   })
              //   .catch(error => {
              //     console.error('Error searching documents:', error);
              //   });
            // })
            .catch((error)=> {
                console.error("Error updating documents: ", error);
            });
            resolve();
          })
          .catch((error) => {
            console.error('Error updating document: ', error);
            resolve();
          });
      });
    };
    let value = ctx.item.isDeleted;
    var index = (currentPage.value-1) * perPage.value + ctx.index + 1
      db
        .collection(dbCollections.UNIVERSITIES)
        .doc(ctx.item.id)
        .update({
          isDeleted:!ctx.item.isDeleted,
          'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
        })
        .then(()=>{
          if(value == false) {
            db
            .collection(dbCollections.COLLEGES)
            .where('universityId','==',ctx.item.id)
            .where('isDeleted','==',false)
            .get()
            .then((querySnapshot)=>{
              if(!querySnapshot.empty) {
                let findIndex = universityArray.value.findIndex((e) => e.id == ctx.item.id);
                if (findIndex !== -1) {
                  universityArray.value[findIndex] = { ...universityArray.value[findIndex], 'isDeleted': !ctx.item.isDeleted };
                }
                const updatePromises = querySnapshot.docs.map(doc => updateFunction(doc));
                Promise.allSettled(updatePromises)
                  .then(() => {
                    refetchData()
                    callback({ 'msg': 'university disable successfully', opt: true });
                  })
                  .catch((error) => {
                    console.error('Error updating documents: ', error);
                  });
              } else {
                let findIndex = universityArray.value.findIndex((e)=>e.id == ctx.item.id);
                if(findIndex !== -1) {
                  universityArray.value[findIndex] = {...universityArray.value[findIndex],'isDeleted': !ctx.item.isDeleted}
                }
                callback({'msg':'university disable successfully',opt:true})
                refetchData()
              }
            }).catch((error)=>{
              console.error(error)
              callback({'msg':'some thing went wronge',opt:false})
            })
          } else{
            let findIndex = universityArray.value.findIndex((e)=>e.id == ctx.item.id);
              if(findIndex !== -1) {
                universityArray.value[findIndex] = {...universityArray.value[findIndex],'isDeleted': !ctx.item.isDeleted}
              }
            callback({'msg':'university enable successfully',opt:true})
            refetchData()
          }
        })
        .catch(error=>{
          console.log(error)
          callback({'msg':'some thing went wronge',opt:false})
        })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    deleteField,
    createUniversity,
    editUniversity,
    editUniversityAndCollege,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    isSpinner,

    // Extra Filters
    filterCountry,
    filterState,
    filterCity,
    allUniversitys,
    refreshPage,
    fetchUniversity,
    universityArray,
    filteredUniversityList,
    nextDisabled,
    isFetching,
    docCategoryTable,
    fetchMoreDataStop,
    fetchUniversityFromTypesense,
    handleSortChange,
    handlePageChange,
    handleSearch
  }
}
